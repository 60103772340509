import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Main from "../../components/layout/main"
import Seo from "../../components/seo/seo"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import { Columns, Content, Rows } from "../../components/grid"
import Heading from "../../components/content/heading"
import Copy from "../../components/content/copy"
import parseBlogContent from "../../utils/parse-blog-content"
import SectionBackground from "../../components/containers/section-background"
import Link from "../../components/content/link"
import parseBlogSidebar from "@src/utils/parse-blog-sidebar"
import CtaGroup from "@src/components/containers/cta-group"

function Article({ data }) {
  const { title, seo, slug, glossaryCategories, content } = data.wpGlossary

  const seoData = {
    title: seo.title,
    description: seo.metaDesc,
    url: `/glossary/${slug}/`,
  }

  const [percentScrollY, setPercentScrollY] = useState(0)
  const blogContent = useRef(null)

  function logit() {
    let percentScroll = (
      window.pageYOffset / blogContent.current.offsetHeight
    ).toFixed(3)
    if (percentScroll < 0) percentScroll = 0
    if (percentScroll > 1) percentScroll = 1
    setPercentScrollY(percentScroll)
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit)
    }
    watchScroll()
    return () => {
      window.removeEventListener("scroll", logit)
    }
  })

  return (
    <Main>
      <Seo customData={seoData} isBlogPost />
      <header>
        <Section theme="dark" wave="blog">
          <SectionBackground>
            <div className="absolute height--full width--full hero-image--glossary" />
          </SectionBackground>
          <SectionContent
            hero
            className="tab-mb-lg"
            paddingBot="lg"
            paddingTop="lg"
          >
            <Rows className="tab-mb-lg">
              <Columns count="1" className="mob-mb-lg tab-mb-xl tab-mt-lg">
                <Content paddingLeft="none" paddingRight="none">
                  <CtaGroup alignment="center">
                    <p>
                      <Link className="link--light" to="/glossary/">
                        Glossary
                      </Link>
                    </p>
                  </CtaGroup>
                  <Heading alignment="center" level={1}>
                    {title}
                  </Heading>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section className="section--blog">
        <SectionContent paddingTop="xxs">
          <Rows>
            <Columns count="1,3">
              <Content paddingLeft="none" className="blog-post__aside">
                <div className="blog-post__sidebar">
                  <Rows gap="sm">
                    <h4>Contents</h4>
                    <Rows gap="xs">{parseBlogSidebar(content)}</Rows>
                  </Rows>
                </div>
              </Content>
              <article ref={blogContent}>
                <Content paddingRight="none">
                  <Copy className="blog-post__content">
                    {parseBlogContent(content)}
                  </Copy>
                </Content>
              </article>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <div className="blog__scroll-percent">
        <div
          className="blog__scroll-percent__inner"
          style={{
            width: `${100 * percentScrollY}%`,
          }}
        />
      </div>
    </Main>
  )
}

export default Article

export const postQuery = graphql`
  query ($id: String!) {
    wpGlossary(id: { eq: $id }) {
      title
      slug
      content
      glossaryCategories {
        nodes {
          name
        }
      }
      seo {
        title
        metaDesc
      }
    }
  }
`
